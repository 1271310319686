import revive_payload_client_YuithMLiJX from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_4abzwtlmacjmlt2pwo6k4lqclq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ABFGEgoIbV from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_4abzwtlmacjmlt2pwo6k4lqclq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Z0gHkLWQJS from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_4abzwtlmacjmlt2pwo6k4lqclq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_xUqUCKZk82 from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_4abzwtlmacjmlt2pwo6k4lqclq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_YycYLycC5Y from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_4abzwtlmacjmlt2pwo6k4lqclq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_fhk1cXTSt4 from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_4abzwtlmacjmlt2pwo6k4lqclq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ikd1Do1ty6 from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_4abzwtlmacjmlt2pwo6k4lqclq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_i1xr3ULj6u from "/builds/ldseating/nido/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/ldseating/nido/.nuxt/components.plugin.mjs";
import prefetch_client_7awMfNLCnb from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_4abzwtlmacjmlt2pwo6k4lqclq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_4n90QkmTQv from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.19.2_qrcode@1.5.4_rollup@4.26.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_V9PT4FiqOf from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.19.2_qrcode@1.5.4_rollup@4.26.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_4QA2zpo856 from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.19.2_qrcode@1.5.4_rollup@4.26.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_7PijDAVuNh from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@4.26.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_kweUxXFrwC from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+icon@1.7.2_rollup@4.26.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_EjR3ipLBGx from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.26.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ObTBmNDEqR from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.26.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_shVUlIjFLk from "/builds/ldseating/nido/plugins/sentry.client.ts";
export default [
  revive_payload_client_YuithMLiJX,
  unhead_ABFGEgoIbV,
  router_Z0gHkLWQJS,
  payload_client_xUqUCKZk82,
  navigation_repaint_client_YycYLycC5Y,
  check_outdated_build_client_fhk1cXTSt4,
  chunk_reload_client_ikd1Do1ty6,
  plugin_vue3_i1xr3ULj6u,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7awMfNLCnb,
  slideovers_4n90QkmTQv,
  modals_V9PT4FiqOf,
  colors_4QA2zpo856,
  plugin_client_7PijDAVuNh,
  plugin_kweUxXFrwC,
  switch_locale_path_ssr_EjR3ipLBGx,
  i18n_ObTBmNDEqR,
  sentry_client_shVUlIjFLk
]