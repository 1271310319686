import routerOptions0 from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_4abzwtlmacjmlt2pwo6k4lqclq/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/ldseating/nido/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}